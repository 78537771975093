import React, { useState, useRef } from "react";
import "./ProgrammaticSeo.scss";
import { useQuery } from "@tanstack/react-query";
import { generateSEOTitlesQueryFn } from "../../utils/api";
import SuccessAlert from "../../components/SuccessAlert/SuccessAlert";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";
import { useNavigate } from "react-router-dom";
import type { CountryType } from "../../utils/constants/CountriesforSerp";


const ProgrammaticSeo = () => {
    const [nTitles, setNTitles] = useState("");
    const [pattern, setPattern] = useState("");
    const [examples, setExamples] = useState(["", "", ""]);
    const [showLoading, setShowLoading] = useState(false); 
    const [triggerQuery, setTriggerQuery] = useState(false);
    const successAlertRef = useRef<any>(null);
	const errorAlertRef = useRef<any>(null);
    const [isValid, setIsValid] = useState<boolean>(true);    
    const [isPatternValid, setIsPatternValid] = useState<boolean>(true);
    const [isExampleValid, setIsExampleValid] = useState<boolean[]>([]); 
    const [hasTouchedExamples, setHasTouchedExamples] = useState<boolean[]>([false, false, false]); 
    const [selectedLocation, setSelectedLocation] = useState<CountryType>({        
		location_code: 1,
		location_name: 'Global',
		country_iso_code: 'ZZ',
	});

    const navigate = useNavigate();

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setNTitles(value);

        // Validate the input: it should be a positive number
        if (Number(value) > 0) {
            setIsValid(true); // Input is valid
        } else {
            setIsValid(false); // Input is invalid
        }
    };

    const handleBackClick = () => {
            navigate('/title-project');
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        setShowLoading(true);
        setTriggerQuery(true);
    };

    const handleRemoveExample = (index: number) => {
        const newExamples = examples.filter((_, i) => i !== index);
        setExamples(newExamples);
    };

    const handleAddExample = () => {
        if (examples.length < 15) {
            setExamples([...examples, ""]);
            setIsExampleValid([...isExampleValid, false]); // Default new example validity to false
        }
    };
    

    const { data, error, isLoading, isFetching } = useQuery<any, Error>(
        ["generateSEOTitlesAPI", nTitles, pattern, examples, selectedLocation],
        () =>
            generateSEOTitlesQueryFn(
                parseInt(nTitles, 10),
                pattern,
                examples.filter((example) => example.trim() !== ""),
                selectedLocation,
            ),
        {
            enabled: triggerQuery,
            refetchOnWindowFocus: false,
            retry: 1,
            onSuccess: (responseData) => {
                setTriggerQuery(false);
                setShowLoading(false);
                // Show success message
                // setSuccessMessage("Titles successfully generated!");
                successAlertRef.current?.show("Titles successfully generated!");
                setTimeout(() => {
                    successAlertRef.current?.close();
                }, 3000);
                
                // Reset form fields after success
                setNTitles("");
                setPattern("");
                setExamples(["", "", ""]);

                setTimeout(() => {
                    navigate('/title-project');
                }, 3100);
            },
            onError: () => {
                setTriggerQuery(false);
                setShowLoading(false);
                errorAlertRef.current?.show("Failed to generate title");
                setTimeout(() => {
                    errorAlertRef.current?.close();
                }, 3000);
            }
        }
    );

    
    const validatePattern = (value: string) => {
        const curlyBracketRegex = /\{[^{}]+\}/g;
        const matches = value.match(curlyBracketRegex);
        
        // Check if there are unmatched or invalid curly braces
        const openBraces = (value.match(/\{/g) || []).length;
        const closeBraces = (value.match(/\}/g) || []).length;
        
        return matches !== null && matches.length > 0 && openBraces === closeBraces;
    };
    
    const handlePatternChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setPattern(value);
        setIsPatternValid(validatePattern(value)); // Validate the input
        setHasTouchedExamples([...hasTouchedExamples, false]);
    };
    
    const handleExampleChange = (index: number, value: string) => {
        const updatedExamples = [...examples];
        updatedExamples[index] = value;
        setExamples(updatedExamples);

        const updatedTouched = [...hasTouchedExamples];
        updatedTouched[index] = true;
        setHasTouchedExamples(updatedTouched);

        const updatedValidities = [...isExampleValid];
        updatedValidities[index] = validatePattern(value);
        setIsExampleValid(updatedValidities);
    };

    const areFirstThreeFilled = examples.slice(0, 3).every((example) => example.trim() !== "");

    return (        
        <div className="ps-container">
            <div className={"seo-project-header"}>
				<svg className={"back-btn seo-back"} onClick={handleBackClick} stroke="#bcbcbc" fill="#bcbcbc" width="28" height="24" viewBox="0 0 28 24">
					<path d="M27.5 12H2M2 12L13 1M2 12L13 23" stroke="black" strokeOpacity="0.5" strokeWidth="2" />
				</svg>
			</div>
            <h1 className="ps-header">SEO Title Generator</h1>
            <div className="seo-form-container">
                <h2>Generate SEO-Optimized Titles</h2>
                <form onSubmit={handleSubmit} className="seo-form">
                    <div className="form-group bottom-space">
                        <label className="seo-text" htmlFor="nTitles">Total Number of Titles</label>
                        <input
                                className="input-with-placeholder"
                                type="text"
                                id="nTitles"
                                value={nTitles}
                                onChange={handleInputChange}
                                placeholder="20"
                                required
                                style={{
                                    border: isValid ? "1px solid #ced4da" : "1px solid red", // Red border for invalid input
                                }}
                            />
                            {!isValid && (
                                <small className="error" style={{ color: "red", alignSelf: "center" }}>Please enter a number greater than zero.</small>
                            )}
                    </div>
                    <div className="form-group bottom-space">
                        <label className="seo-text" htmlFor="pattern">Title Pattern</label>
                        <input
                            className="input-with-placeholder"
                            type="text"
                            id="pattern"
                            value={pattern}
                            onChange={handlePatternChange}
                            placeholder="e.g., Bike Rental in {location}"
                            required
                            style={{
                                border: isPatternValid ? "1px solid #ced4da" : "1px solid red", // Red border for invalid input
                            }}
                        />
                        {!isPatternValid && (
                            <small className="error" style={{ color: "red", alignSelf: "center" }}>
                                Please include at least one valid placeholder in the format {"{example}"}. Ensure text is inside the curly braces.
                            </small>
                        )}
                    </div>
                    <div className="form-group" >
                        <label className="seo-text">Examples (at least 3)</label>
                        {examples.map((example, index) => (
                            <div key={index} className="example-input-container bottom-space">
                                <input
                                    type="text"
                                    className="example input-with-placeholder"
                                    value={example}
                                    onChange={(e) => handleExampleChange(index, e.target.value)}
                                    placeholder={`e.g., Bike Rental in {mumbai}`}
                                    // style={{ alignSelf: "center" }}
                                    required
                                    style={{
                                        border:
                                            hasTouchedExamples[index] && !isExampleValid[index]
                                                ? "1px solid red"
                                                : "1px solid #ced4da",
                                    }}
                                />                                
                                {index >= 3 && (
                                    <button
                                        type="button"
                                        className="remove-btn"
                                        onClick={() => handleRemoveExample(index)}
                                    >
                                        x
                                    </button>
                                )}
                                <div className="errormessage">
                                {hasTouchedExamples[index] && !isExampleValid[index] && (
                                   <small className="error" style={{ color: "red", alignSelf: "center" }}>
                                       Please include a valid placeholder like {"{example}"}. Avoid unmatched or empty braces.
                                   </small>
                                )}
                                </div>
                            </div>
                        ))}
                        <button
                            type="button"
                            className="add-btn"
                            onClick={handleAddExample}
                            disabled={!areFirstThreeFilled}
                        >
                            + Add Example
                        </button>
                    </div>
                    <button type="submit" className="submit-btn" disabled={!isValid || !nTitles || !pattern}>
                        Generate Titles
                    </button>
                    {(isLoading || isFetching) && showLoading && (
                            <p
                                style={{
                                    textAlign: "center",
                                    fontSize: "1.3rem",
                                }}
                            >
                                Loading Data...
                                <img
                                    src="/static/media/spinner.a81353c0a4707f9915a4835a359de143.svg"
                                    alt="spinner"
                                    className="spinner-spin ml-5"
                                    style={{
                                        width: "1.4em",
                                        height: "auto",
                                        verticalAlign: "middle",
                                    }}
                                />
                            </p>
                        )}
                        {error && showLoading && (
                            <p
                                style={{
                                    textAlign: "center",
                                    fontSize: "1.3rem",
                                }}
                            >
                                An error occurred.
                                <img
                                    src="/static/media/spinner.a81353c0a4707f9915a4835a359de143.svg"
                                    alt="spinner"
                                    className="spinner-spin ml-5"
                                    style={{
                                        width: "1.4em",
                                        height: "auto",
                                        verticalAlign: "middle",
                                    }}
                                />
                            </p>
                        )}
                </form>
                <SuccessAlert ref={successAlertRef} />
				<ErrorAlert ref={errorAlertRef} />
            </div>
        </div>
    );
};

export default ProgrammaticSeo;